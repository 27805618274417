<template>
<div class="row">

         <div class="text-center mt-5 " v-if="cargando">
            <div class="spinner-border text-primary spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <br>
            Cargando ...
        </div>

   <div v-if="!cargando">
        <div class="col-md-9">

                <!-- Clase -->
                <div class="bg-white p-4 mb-2" :style="'border-top: 5px solid '+ infoclase.asignaturas.color" >
                        <span class="h4 titulosg me-3"> {{infoclase.titulos}} </span> 
                        
                        <div class="mt-3">
                            <span class="pe-2 ps-2 pt-1 pb-1 rounded  bg-success"  v-if="infoclase.tipoactividad==1">Clase</span>
                            <span class="pe-2 ps-2 pt-1 pb-1 rounded  bg-warning"  v-if="infoclase.tipoactividad==2">Trabajo en Casa</span>
                            <span class="pe-2 ps-2 pt-1 pb-1 rounded  bg-danger text-white"  v-if="infoclase.tipoactividad==3">Actividad de Recuperación</span>
                        </div>

                        <table class="mt-3 mb-3" >
                            <tbody>
                                <tr style="width: 120px;">
                                    <td class="text-secondary">Grado: </td>
                                    <td>   {{infoclase.grupos.nombregradogrupo}}  </td>
                                </tr>
                                <tr>
                                    <td class="text-secondary">Asignatura: </td>
                                    <td>  {{infoclase.asignaturas.descripcion}}  </td>
                                </tr> 
                                <tr>
                                    <td class="text-secondary">Periodo: </td>
                                    <td>   P{{infoclase.periodos.numero}} -    {{formatoFecha(infoclase.periodos)}} /  {{formatoFecha(infoclase.periodos)}}      </td>
                                </tr> 
                            </tbody>
                        </table>

                        <div class="row mb-1 mt-2">
                            <div class="col-md-6"> <div class="bg-light p-2 me-2 text-capitalize"> <i class="far fa-calendar-alt"></i> Inicio:  <strong>{{formatoFecha(infoclase.fechainicio)}}</strong> </div>  </div>
                            <div class="col-md-6"> <div class="bg-light p-2 me-2 text-capitalize"> <i class="far fa-calendar-alt"></i> Cierre:  <strong>{{formatoFecha(infoclase.fechacierre)}}</strong> </div>  </div>
                        </div>
                        
                        <div class="mt-3" v-html="infoclase.descripcion"></div>

                </div>

                <div class="bg-white p-4">
                    <strong> <i class="fas fa-paperclip"></i> Archivos Adjuntos <span class="badge bg-primary"> {{ infoclase.archivos.length }} </span> </strong>
                    
                    <ul class="list-group list-group-flush mt-3">
                        <li class="list-group-item"  v-for="item in infoclase.archivos" :key="item.idacaanexosclase">
                            <a :href="archivospublicos+'/clases/'+item.archivo" target="blank" > 
                                <i class="fas fa-file"></i> {{item.descripcion}}
                            </a>
                        </li>
                    </ul>

                </div>

                 <div class="p-2 text-secondary mb-5">
                     <strong>Creado:</strong>  {{formatoFecha(infoclase.created_at)}} <br>
                     <strong>Actualizado:</strong>  {{formatoFecha(infoclase.updated_at)}}
                 </div>
                <div>

                </div>
        </div>
    </div>
</div>
</template>

<script>

import { ref, onMounted, reactive } from 'vue'
import axios from 'axios'
import moment from 'moment'

import { useRoute, useRouter } from 'vue-router'


export default {
    props: ['slug'],
    setup(props) {

        const router = useRouter()
        const route = useRoute()

        const cargando = ref(true)

        
        const infoclase = ref({
            grupos: {},
            asignaturas: {},
            periodos: {},
            archivos: []
        })

        const consultarclase = () => {
            axios.get('/api/infoclaseslug/'+props.slug).then(response => {
                infoclase.value = response.data[0]
                cargando.value = false;

            })
        }
    
        const formatoFecha = (date) => {
            return moment(date).format('D MMMM [de] YYYY');
        }
 
        onMounted(() => {
            consultarclase()
        })

        return {
            infoclase,
            cargando,

            //funciones
            formatoFecha,
        }

    }
}
</script>

<style>

</style>
